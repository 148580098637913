/* eslint-disable jsx-a11y/heading-has-content */
import '../Style.css';
import DailyAvailabilityContent from './dailyavailabilitycontent';
import NoresultsFound from './NoresultsFound';
import FutureAvailability from './futureavailability';
import moment from 'moment';
import {useParams} from "react-router-dom";
import Loader from './loader';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import RestHelper from '../RestHelper';
// minimum date setting
let minDate = moment().format('YYYY-MM-DD');
let maxDate = moment().add(30, 'days').format('YYYY-MM-DD');

function backRedirect()
{
  let date = localStorage.getItem('the_date');
  let cat = localStorage.getItem('category');
  let spez = localStorage.getItem('specialization');
 if(localStorage.getItem('category')!== 'undefined' && localStorage.getItem('specialization')!== 'undefined') {
  window.location.href='/search/'+date+'/'+cat+'/'+spez;
 } else if(localStorage.getItem('category')!== 'undefined') {
  window.location.href='/search/'+date+'/'+cat;
 } else {
  window.location.href='/search/'+date;
 }
 
//  window.location.href='/search/'+date;
//  if(date && !cat && !spec) {
//     window.location.href='/search/'+date;
//  } else if(date && cat && !spec) {
//    window.location.href='/search/'+date+'/'+cat;
//  } else if(date && cat && spec) {
//    window.location.href='/search/'+date+'/'+cat+'/'+spec;
//  }
}
function ProfileData(data) {
    const [loading,IsLoad] = useState(false);
    const result = data.details;
    const future = data.future;
    const date = data.search_date;
    const [search_date, setSearchDate] = useState(date);

    useEffect(()=>{
      getSearchData(data.search_date);
    },[])
    async function getSearchData(date) {
      setSearchDate(date);
      IsLoad(true);
      let client_id = document.getElementById("client_id").value;

      let container = document.getElementById('tableData');
      let tabledata = createRoot(container);  // Create a root.
      let container1 = document.getElementById('futureid');
      let futurelist = createRoot(container1);  // Create a root.
      let apiname = "search";
      let method = "post";
      let body = {
        sch_date: date,
        client_id: client_id
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          let response_1 = '';
          if(response != '' && response.result) {
            response_1 = response.result;
          }
          let future = '';
          if(response != '' && response.future_availability) {
            future = response.future_availability;
          };

          futurelist.render(<FutureAvailability future={future} getSearchData={getSearchData} />);
           if(response_1.length) {
            tabledata.render(<DailyAvailabilityContent daily={response_1} customDataObject={response.customDataObject}/>);
            } else {
              tabledata.render(<NoresultsFound  />);
           }
        }
        IsLoad(false);
      } catch (err) {
        console.log(err);
        IsLoad(false);
      }
  }
    const loader = loading?<Loader />:null;
    return (
      <>
      {loader}
          <div className="container token-container" style={{paddingTop:"50px"}}>
              <div className="row justify-content-center">
                <div className="col-md-12"><a onClick={()=>backRedirect()}><button className="btn btn-danger btn-sm"><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Back</button></a>
                </div>
            </div>
          </div>
            <section className="appoinment section" style={{paddingTop:"50px"}}>
            <div className="container token-container">
              <div className="row justify-content-center">
                <div className="col-md-5  order-2 order-md-1 profile-mobile" style={{background: "#fff",borderRadius: "15px 15px 15px 15px",boxShadow: "0px 0px 10px",marginLeft: "14px",marginRight:"14px"}}>
                    <div className="row">
                     <div className="col-md-12" style={{padding:"25px"}}>
                        <div className="mt-3">
                            <div className="row">
                                <div className="col-sm-4">
                                <i className="fa fa-user-circle" style={{fontSize: "110px"}}></i>
                                    {/* <img src="../assets/images/profile/user.webp" alt="" style={{textAlign:"center"}} className="img-circle img-fluid" /> */}
                                </div>
                                <div className="col-md-8">
                                    <input type="hidden" value={result.client_id} id="client_id" />
                                    <h2 className="h2" id="name">{result.name}</h2>
                                    <h6>{result.specialization}  </h6>
                                    <h6>{result.company_name !== result.name?result.company_name:(null)}</h6>
                                    <h6>{result.company_phone}</h6>
                                    <h6 style={{fontSize:"14px",color:"grey"}}>{result.company_address}</h6>
                                </div>
                            </div>
                        
                            <div className="row">
                                <div className="col-md-12"><br />
                                            <h4 className="mb-3">Future Availability</h4>
                                            <table className="w-hours list-unstyled table">
                                              <thead style={{textAlign:"center"}}>
                                                <tr>
                                                  <th>DATE</th>
                                                  <th>M/N</th>
                                                  <th>A/N</th>
                                                  <th>E/N</th>
                                                </tr>
                                              </thead>
                                              <tbody style={{textAlign:"center"}} id="futureid">
                                               {/* Future availability data */}
                                               <FutureAvailability future={future} getSearchData={getSearchData} />
                                              </tbody>
                                            </table>
                                
                                </div>
                            </div>
                            <div className="row" style={{textAlign:"bottom"}}>
                                <div className="col-md-12">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d741522.2093673615!2d75.10292119197267!3d10.524460400914958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d514abec6bf%3A0xbd582caa5844192!2sKochi%2C%20Kerala!5e0!3m2!1sen!2sin!4v1638354585362!5m2!1sen!2sin" width="100%" height="100%" style={{border:"0"}} title="google map" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-6 order-1 order-md-2 mobile-top" style={{background: "#fff",borderRadius: "15px 15px 15px 15px",boxShadow: "0px 0px 10px",marginLeft:"14px",marginRight:"14px"}}>
                    <div className="appoinment-wrap mt-5 mt-lg-0">
                        <h4 className="mb-3">Check Availability</h4>
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="form-group">
                                        <input className="form-control" id="date_value" value={search_date} onChange={(e)=>setSearchDate(e.target.value)} min={minDate} max={maxDate} type="date" />
                                    </div>
                                </div>
                                <div className="col-lg-1">
                                    <div className="form-group">
                                        <button className="btn btn-danger btn-sm" onClick={()=>getSearchData(search_date)} type="button">
                                          {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                                          Search
                                        </button>
                                    </div>
                                </div>   
                            </div>
                                    <div className="feature-item mb-5 mb-lg-0" style={{margin: "0px",padding: "4px"}}>
                                        <div className="feature-icon mb-4">
                                            <i className="icofont-ui-clock"></i>
                                            
                                        </div>
                                            <span>Timing schedule</span>
                                            <h4 className="mb-3">Available Hours of {moment(search_date).format('DD-MM-YYYY')}</h4>
                                            <table className="w-hours list-unstyled table booking-list-tbl" id="tableData">
                                             
                                            </table>
                                              
                                    </div>
                        </div>
                      </div>
                    </div>
              </div>
            </section>

      </>
    );
 
}
export default ProfileData;
