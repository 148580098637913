/* eslint-disable no-unused-vars */
import React from 'react';
import Home from './pages/home';
import ClientProfile from './pages/clientprofile';
import DetailedSearch from './pages/detailedsearch';
import DailyAvailability from './pages/dailyavailability';
import WeeklyAvailability from './pages/weeklyavailability';
import Contact from './pages/contact';
import Category from './pages/category';
import TokenInfo from './pages/tokeninfo';
import TokenDetails from './pages/tokendetails';
import TokenBookingList from './pages/tokenbookinglist';
import Profile_s from './pages/profile';
import DoctorLogin from './pages/doctorlogin';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomerLogin from './pages/customerlogin';
import DoctorRegistration from './pages/doctorregistration';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile/:slug" element={<ClientProfile />} />
        <Route path="/profile/:slug/:the_date" element={<ClientProfile />} />
        <Route path="/search" element={<DetailedSearch />} />
        <Route path="/search/:searchdate" element={<DetailedSearch />} />
        <Route path="/search/:searchdate/:category" element={<DetailedSearch />} />
        <Route path="/search/:searchdate/:category/:subcategory" element={<DetailedSearch />} />
        <Route path="/set_daily_availability" element={<DailyAvailability/>}/>
        <Route path="/set_weekly_availability" element={<WeeklyAvailability/>}/>
        <Route path="/contact" element={<Contact />} />
        <Route path="/client" element={<DoctorLogin />} />
        <Route path="/customer" element={<CustomerLogin />} />
        <Route path="/doctor-registration" element={<DoctorRegistration />} />
        <Route path="/category" element={<Category />} />
        <Route path="/tokeninfo" element={<TokenInfo />} />
        <Route path="/tokendetails/:the_date/:slot" element={<TokenDetails />} />
        <Route path="/token-list" element={<TokenBookingList />} />
        <Route path="/myprofile" element={<Profile_s />} />
      </Routes>
  </Router>
  );
}

export default App;
