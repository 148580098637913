import '../Style.css';

function storeDate(url,the_date,cat,spec) {
    localStorage.setItem('the_date', the_date);
    localStorage.setItem('category', cat);
    localStorage.setItem('specialization', spec);
    document.location.href=url;
}
function CardComponent(data) {
    const result = data.c_name;
    const date = data.date;
    const cat = data.category;
    const spec = data.specialization;
    const currentdate = data.currentdate;
    const currenttime = data.currenttime;

    return (
      <>
      <div className="row">
        <div className="col-md-12 search-result-head"><h5 className="mb-3 d-flex flex-column" id="search_text">Your Search Results</h5></div>
      </div>
      <div className="row">
      {result.map(function(d, idx){ 
         let url = "/profile/"+d.slug+"/"+date;
         
         // booking closed
         let is_m_closed = 0;
         let is_a_closed = 0;
         let is_e_closed = 0;
         if(date === currentdate) {
            if(d.m_available === 1) {
                if(d.morning_end < currenttime) {
                    is_m_closed = 1;
                }
            } else {
                is_m_closed = 1; 
            }
            if(d.a_available === 1) { 
                if(d.afternoon_end < currenttime) {
                    is_a_closed = 1;
                }
            } else {
                is_a_closed = 1;
            }
            if(d.e_available === 1) { 
                if(d.evening_end < currenttime) {
                    is_e_closed = 1;
                }
            } else {
                is_e_closed = 1;
            }
         }
       
         return (
         <div className="col-md-6 col-sm-6"  key={idx}>
            <div className='doctor-card-wrapper'>
                    <div className='doctor-wrapper-1'>
                        <div className='doctor-category-wrapper'>
                            <h4 className="doctor-category">{d.company_admin === 1?d.company_name:d.job_name}</h4>
                        </div>
                        <div className="doctor-inner-wrapper-1">
                            <div class="main-section">
                                <h2 className="h2 text-right doctor-name">{d.name.substring(0, 14)}</h2>
                                <p className='text-right doctor-category-head'><strong></strong> {d.specialization} </p>
                            </div>
                            <div className="right text-center">
                                <i className="fa fa-user-circle doctor-profile-img"></i>
                                {/* <img src="assets/images/profile/user.webp" alt="" className="img-circle img-fluid" /> */}
                            </div>
                        </div>
                    </div>
                    <div className='doctor-wrapper-2'>
                       <p className='doctor-address'>{d.address}</p>
                    </div>
                    <div className='doctor-wrapper-1'>
                            <div class="doctor-map-phone-wrapper row">
                                <div className='doctor-map-phone col-6'>
                                    <i className='fa fa-map-marker-alt outlined-icon'></i>{d.company_location}
                                </div>
                                <div className='doctor-map-phone col-6'>
                                    <i className='fa fa-phone fa-flip-horizontal outlined-icon'></i>{d.mobile_phone}
                                </div>
                            </div>
                        </div>  
                        
                    <div className='row doctor-slot-wrapper'>
                        <div className=" col-sm-8 emphasis align-self">
                           {date === currentdate?(   
                            <>
                            {d.m_available === 1 && d.morning_end > currenttime?<span className="badge badge-pill badge-fill-success">Morning : {d.m_maxtoken}</span>:<span className="badge badge-pill badge-fill-default">Morning : {d.m_maxtoken}</span>}
                            {d.a_available === 1 && d.afternoon_end > currenttime?<span className="badge badge-pill badge-fill-success">Afternoon : {d.a_maxtoken}</span>:<span className="badge badge-pill badge-fill-default">Afternoon : {d.a_maxtoken}</span>}
                            {d.e_available === 1  && d.evening_end > currenttime?<span className="badge badge-pill badge-fill-success">Evening : {d.e_maxtoken}</span>:<span className="badge badge-pill badge-fill-default">Evening : {d.e_maxtoken}</span>}
                            </>
                            ):(
                            <>
                            {d.m_available === 1?<span className="badge badge-pill badge-fill-success">Morning : {d.m_maxtoken}</span>:<span className="badge badge-pill badge-fill-default">Morning : 0</span>}
                            {d.a_available === 1?<span className="badge badge-pill badge-fill-success">Afternoon : {d.a_maxtoken}</span>:<span className="badge badge-pill badge-fill-default">Afternoon : 0</span>}
                            {d.e_available === 1?<span className="badge badge-pill badge-fill-success">Evening : {d.e_maxtoken}</span>:<span className="badge badge-pill badge-fill-default">Evening : 0</span>}
                            </>
                            )} 
                        </div>
                        <div className=" col-sm-4 emphasis">
                            {date === currentdate && is_m_closed == 1 && is_a_closed == 1 && is_e_closed == 1?(
                                <span className='booking-closed-text'>Booking closed</span>
                            ):(
                            // <a onClick={()=>storeDate(url,date,cat,spec)}><button type="button" className="btn btn-main-2 btn-sm" style={{margin: "10px"}}>Book
                            // </button></a>
                            <button onClick={()=>storeDate(url,date,cat,spec)} className='book-slot-btn'>Book</button>
                            )}
                        </div>
                    </div>
            </div>
        </div>
        )
       })}
       </div>
      </>
    );
 
}
export default CardComponent;
