import DailyAvailabilityContent from './dailyavailabilitycontent';
import { useState } from "react";
import Loader from './loader';
import ReactDOMClient from 'react-dom/client';
import RestHelper from '../RestHelper';
function sessionDestroy() {
    localStorage.removeItem('session_key');
    localStorage.removeItem('type');
    window.location.href="/";
  }
  function showPop() {
    window.location.href='/customer';
  }
function BookedComponent(prop) {
    const [status] = useState(prop.status);
    const [token_no] = useState(prop.token_no);
    const [called] = useState(prop.called);
    const [date] = useState(prop.date);
    const [client_id] = useState(prop.client_id);
    const [time_from] = useState(prop.time_from);
    const [time_to] = useState(prop.time_to);
    const [loading,IsLoad] = useState(false);
    let slot_type = '';
    if(status === 'Morning') {
        slot_type = 'M';
    } else if(status === 'Afternoon') {
        slot_type = 'A';
    } else {
        slot_type = 'E';
    }
    const [slot] = useState(slot_type);
    async function CancelToken(date,client_id,slot) {
        let session_key = localStorage.getItem('session_key');

        let container = document.getElementById('tableData');
        let tabledata = ReactDOMClient.createRoot(container);  // Create a root.
        if(session_key) { 
            var returnValue = window.confirm('Are you sure You want to cancel this token?');
            if(returnValue === true) {
                IsLoad(true);
                let client_id = document.getElementById("client_id").value;
                let apiname = "cancelToken";
                let method = "post";
                let body = {
                    date: date,
                    client_id: client_id,
                    slot:slot
                };
                try {
                    let response = await RestHelper(apiname, method, body);
                    if (response) {
                        if(response.code === 200) {
                            let apiname = "search";
                            let method = "post";
                            let body = {
                                sch_date: date,
                                client_id: client_id
                            };
                            try {
                                let response = await RestHelper(apiname, method, body);
                                if (response) {
                                    let response_1 = response.result;
                                    if(response_1.length) {
                                        tabledata.render(<DailyAvailabilityContent daily={response_1} customDataObject={response.customDataObject}/>);
                                    }   
                                }
                            } catch (err) {
                                console.log(err);
                            }
                        } else if(response.code === 401) {
                            alert(response.message);
                           
                        } else {
                            alert(response.message);
        
                        }
                    }
                    IsLoad(false);
                } catch (err) {
                console.log(err);
                IsLoad(false);
                }
            } else {
                IsLoad(false); 
            }
        } else {
            alert('Please login to book your slot');
            IsLoad(false);
       
        }
    }
    const loader = loading?<Loader />:null;
    return (
      <>{loader}
        <tr className=" justify-content-between">
            <td colSpan="2" className="available-text">
                <div className="row">
                    <div className="col-5">
                        <b>{status}</b>
                    </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-5 out-fit">
                    <div className="shift_component"><span>{time_from}</span></div>
                  </div>
                  <div className="col-1" style={{padding:"0px",bottom:"12px"}}>
                  <i className='fas fa-arrow-right'style={{fontSize:"10px"}}></i>
                  </div>
                  <div className="col-5 out-fit">
                     <div className="shift_component"><span>{time_to}</span></div>
                  </div>
              </div>
            </td>
            <td colSpan="2">
              <div className="row">
                <div className="col-md-6">
                 <div className="outer">
                  <div className="inner"><b>Live</b></div>
                  <div className="inner-inner"><b>{called}</b></div>
                 </div>&nbsp;&nbsp;&nbsp;
                </div>
                <div className="col-md-6">
                  <div className="outer">
                    <div className="inner-inner1"><b>{token_no}</b></div>
                    <div className="inner1"><b>Token</b></div>
                  </div>
                 </div>
                </div>
            </td>
            <td>
                 <button type="button" className="btn btn-danger btn-sm btn-width" onClick={()=>CancelToken(date,client_id,slot)} style={{float: "right"}}>Cancel</button>
            </td>
        </tr>
      </> 
    );
}

export default BookedComponent;