/* eslint-disable jsx-a11y/heading-has-content */
import '../Style.css';
import { useState,useEffect } from 'react';
import Loader from './loader';
import RestHelper from '../RestHelper';
function MyProfile() {
    const [loading,IsLoad] = useState(false);
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [address,setAddress] = useState('');
    useEffect(() => {
      if(localStorage.getItem('session_key')) { 
        getMyProfile();
      }
    }, []);
    async function getMyProfile() {
      IsLoad(true);
      let apiname = "getMyProfileInfo";
      let method = "post";
      let body = {
        date: ''
      };
      try {
        let response = await RestHelper(apiname, method, body);
        if (response) {
          let data = response.result;
          setName(data.name);
          setAddress(data.address);
          setPhone(data.mobile_phone);
          IsLoad(false);
        }
        IsLoad(false);
      } catch (err) {
        console.log(err);
        IsLoad(false);
      }
    }
    const loader = loading?<Loader />:null;
    return (
      <>
      {loader}
         <section className="page-title bg-1">
              <div className="overlay"></div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="block text-center">
                      <h1 className="text-capitalize mb-5 text-lg">My Profile</h1>
                    </div>
                  </div>
                </div>
              </div>
          </section>
          <div className="container token-container" style={{paddingTop:"50px"}}>
              <div className="row justify-content-center">
                {/* <div className="col-md-12"><button className="btn btn-danger btn-sm"><i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Back</button></a>
                </div> */}
              </div>
          </div>
            <section className="appoinment section" style={{paddingTop:"50px"}}>
            <div className="container token-container">
              <div className="row justify-content-center">
                <div className="col-md-5" style={{background: "#fff",borderRadius: "15px 15px 15px 15px",boxShadow: "0px 0px 10px",marginLeft: "14px",marginRight:"14px"}}>
                    <div className="row">
                     <div className="col-md-12" style={{padding:"25px"}}>
                        <div className="mt-3">
                            <div className="row">
                                <div className="col-sm-4">
                                <i className="fa fa-user-circle" style={{fontSize: "110px"}}></i>
                                    {/* <img src="../assets/images/profile/user.webp" alt="" style={{textAlign:"center"}} className="img-circle img-fluid" /> */}
                                </div>
                                <div className="col-md-8" style={{alignSelf: 'center'}}>
                                    <h2 className="h2" id="name">{name}</h2>
                                    <h6>{phone}</h6>
                                    <h6 style={{fontSize:"14px",color:"grey"}}>{address}</h6>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                  </div>
                </div>
             </div>
            </div>
          </section>
          </>);
}
export default MyProfile;