
function TokenShift(data) {
    let shift = data.customDataObject;
    let date = shift.date;
    return(
        <tbody id="dataShift">
                   {shift.morning_flag === 1?(
                                <div className="table-row">
                                <a href={"tokendetails/" + date + "/M"}>
                                <div className="row row-grey">
                                 <div className="col-md-5">
                                    <div className="available-text">
                                        <div className="row">
                                            <b>{shift.morning}</b>
                                        </div>
                                        <div className="row justify-content-center">
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><b>{shift.morning_start}</b></div>
                                        </div>
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><b>{shift.morning_end}</b></div>
                                        </div>
                                        </div>
                                        <div className="row justify-content-center remains">
                                            Remaining : {shift.remaining_token_m}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="">
                                    <div className="row justify-content-end">
                                       
                                        <div className="col-md-6 token-box">
                                            <div className="outer-doctor">
                                            <div className="inner-doctor"><b>Token No</b></div>
                                            <div className="inner-inner-doctor"><b>{shift.live_token_m}/</b><b className="font-adjust-token">{shift.token_nos_m}</b></div>
                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                        
                                    </div> 
                                    </div>
                                  </div>
                                  </div>
                                </a>
                                </div>
                    ):
                    <div className="table-row">
                                <div className="row row-grey">
                                 <div className="col-md-5">
                                    <div className="available-text">
                                        <div className="row">
                                            <b>Morning</b>
                                        </div>
                                        <div className="row">
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><span>----</span></div>
                                        </div>
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><span>----</span></div>
                                        </div>
                                        </div>
                                        <div className="row justify-content-center remains">
                                            Remaining : 0
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="">
                                    <div className="row justify-content-end">
                                       
                                        <div className="col-md-6 token-box">
                                            <div className="outer-doctor">
                                            <div className="inner-doctor"><b>Token No</b></div>
                                            <div className="inner-inner-doctor"><span>----</span></div>
                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                       
                                    </div> 
                                    </div>
                                  </div>
                                  </div>
                                </div>
                    }

                    {shift.afternoon_flag === 1?(
                                <div className="table-row">
                               <a href={"tokendetails/" + date + "/A"}>
                                <div className="row row-grey">
                                 <div className="col-md-5">
                                    <div className="available-text">
                                        <div className="row">
                                            <b>{shift.afternoon}</b>
                                        </div>
                                        <div className="row justify-content-center">
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><b>{shift.afternoon_start}</b></div>
                                        </div>
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><b>{shift.afternoon_end}</b></div>
                                        </div>
                                        </div>
                                        <div className="row justify-content-center remains">
                                            Remaining : {shift.remaining_token_a}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="">
                                    <div className="row justify-content-end">
                                        
                                        <div className="col-md-6 token-box">
                                            <div className="outer-doctor">
                                            <div className="inner-doctor"><b>Token No</b></div>
                                            <div className="inner-inner-doctor"><b>{shift.live_token_a}/</b><b className="font-adjust-token">{shift.token_nos_a}</b></div>
                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                       
                                    </div> 
                                    </div>
                                  </div>
                                  </div>
                                </a>
                                </div>
                    ):
                    <div className="table-row">
                                <div className="row row-grey">
                                 <div className="col-md-5">
                                    <div className="available-text">
                                        <div className="row">
                                            <b>Afternoon</b>
                                        </div>
                                        <div className="row justify-content-center">
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><span>----</span></div>
                                        </div>
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><span>----</span></div>
                                        </div>
                                        </div>
                                        <div className="row justify-content-center remains">
                                            Remaining : 0
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="">
                                    <div className="row justify-content-end">
                                       
                                        <div className="col-md-6 token-box">
                                            <div className="outer-doctor">
                                            <div className="inner-doctor"><b>Token No</b></div>
                                            <div className="inner-inner-doctor"><span>----</span></div>
                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                       
                                    </div> 
                                    </div>
                                  </div>
                                  </div>
                                </div>
                    }
                   
                    {shift.evening_flag === 1?(
                        
                        <div className="table-row">
                               <a href={"tokendetails/" + date + "/E"}>
                                <div className="row row-grey">
                                 <div className="col-md-5">
                                    <div className="available-text">
                                        <div className="row">
                                            <b>{shift.evening}</b>
                                        </div>
                                        <div className="row justify-content-center">
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><b>{shift.evening_start}</b></div>
                                        </div>
                                        <div className="col-6">
                                            <div className="time-slot-doctor"><b>{shift.evening_end}</b></div>
                                        </div>
                                        </div>
                                        <div className="row justify-content-center remains">
                                            Remaining : {shift.remaining_token_e}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="">
                                    <div className="row justify-content-end">
                                        
                                        <div className="col-md-6 token-box">
                                            <div className="outer-doctor">
                                            <div className="inner-doctor"><b>Token No</b></div>
                                            <div className="inner-inner-doctor"><b>{shift.live_token_e}/</b><b className="font-adjust-token">{shift.token_nos_e}</b></div>
                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                        
                                    </div> 
                                    </div>
                                  </div>
                                  </div>
                                </a>
                                </div>
                        
                    ):
                    <div className="table-row">
                    <div className="row row-grey">
                     <div className="col-md-5">
                        <div className="available-text">
                            <div className="row">
                                <b>Evening</b>
                            </div>
                            <div className="row justify-content-center">
                            <div className="col-6">
                                <div className="time-slot-doctor"><span>----</span></div>
                            </div>
                            <div className="col-6">
                                <div className="time-slot-doctor"><span>----</span></div>
                            </div>
                            </div>
                            <div className="row justify-content-center remains">
                              Remaining : 0
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="">
                        <div className="row justify-content-end">
                           
                            <div className="col-md-6 token-box">
                                <div className="outer-doctor">
                                <div className="inner-doctor"><b>Token No</b></div>
                                <div className="inner-inner-doctor"><span>----</span></div>
                                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                           
                        </div> 
                        </div>
                      </div>
                      </div>
                    </div>
                    }
                </tbody>
    );
}
export default TokenShift;