import DailyAvailabilityContent from './dailyavailabilitycontent';
import { useState } from 'react';
import Loader from './loader';
import NoResultsFound from './NoresultsFound';
import { createRoot } from 'react-dom/client';
import RestHelper from '../RestHelper';

function ShiftComponent(prop) {
    const [loading,IsLoad] = useState(false);
    const status = prop.status;
    const token_count = prop.token_count;
    const time_from = prop.time_from;
    const time_to = prop.time_to;
    const date = prop.date;
    const client_id = prop.client_id;
    let slot = '';
    if(status === 'Morning') {
         slot = 'M';
    } else if(status === 'Afternoon') {
         slot = 'A';
    } else {
         slot = 'E';
    }
    async function BookAtoken(the_date,client_id,slot) {
        IsLoad(true);
        let session_key = localStorage.getItem('session_key');
        if(session_key) { 
            var returnValue = window.confirm('Are you sure to book this slot?');

            let container = document.getElementById('tableData');
            let tabledata = createRoot(container);  // Create a root.
            if(returnValue === true) {
                let apiname = "requestToken";
                let method = "post";
                let body = {
                    date: the_date,
                    client_id: client_id,
                    slot:slot
                };
                try {
                    let response = await RestHelper(apiname, method, body);
                    if (response) {
                        if(response.code === 200) {
                            let apiname = "search";
                            let method = "post";
                            let body = {
                                sch_date: the_date,
                                client_id: client_id
                            };
                            try {
                                let response = await RestHelper(apiname, method, body);
                                let response_1 = response.result;
                                if(response_1.length) {
                                    tabledata.render(<DailyAvailabilityContent daily={response_1} customDataObject={response.customDataObject}/>);
                                } else {
                                    //ReactDOM.render(<NoDataFound  />,document.getElementById("appendResult"));
                                }
                            } catch (err) {
                                console.log(err);
                                IsLoad(false);
                            }
                        } else {
                            alert(response.message);
                        }
                    }
                IsLoad(false);
                } catch (err) {
                console.log(err);
                IsLoad(false);
                }
            } else {
                IsLoad(false); 
            }
        } else {
            IsLoad(false);
            alert('Please login to book your slot');
            window.location.href='/customer'
        }
    }
    const flag = prop.flag;
    if(flag === 0) { 
        const loader = loading?<Loader />:null;
        return (
            
          <>{loader}<>
          {prop.display_flag === 1?(
              <tr className=" justify-content-between">
              <td colSpan="2" className="available-text">
              <div className="row">
                <div className="col-5">
                  <b>{status}</b>
                </div>
              </div>
                {/* <div class="row">
                   <div className="col-md-6 time-slot"><b>{time_from}</b></div>
                   <div className="col-md-6 time-slot"><b>{time_to}</b></div>
                </div> */}
                <div className="row justify-content-center">
                    <div className="col-5 out-fit">
                      <div className="shift_component"><span>{time_from}</span></div>
                    </div>
                    <div className="col-1" style={{padding:"0px",bottom:"10px"}}>
                    <i className='fas fa-arrow-right'style={{fontSize:"10px"}}></i>
                    </div>
                    <div className="col-5 out-fit">
                       <div className="shift_component"><span>{time_to}</span></div>
                    </div>
                </div>
              </td>
              <td colSpan="2">
                  <div className="row justify-content-center">
          
                      <div className="outer-shift">
                          <div className="inner-inner1"><b>{token_count}</b></div>
                          <div className="inner1"><b>Token</b></div>
                      </div>&nbsp;&nbsp;&nbsp;&nbsp;
          
                  </div>
              </td>
              <td style={{textAlign: "right",width:"10px"}}>
                  <button type="button" className="btn btn-info btn-sm btn-width" onClick={()=>BookAtoken(date,client_id,slot)}>Book</button>
              </td>
          </tr>  
          ):(
              null
          )}
          </>
           
        </>
        );
    } else {
        return (<></>);
    }
}

export default ShiftComponent;